import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'app/mobxStore';
import { useTranslation } from 'react-i18next';
import { getClickedHuddle, setClickedHuddle } from '../../../mobxStore/storage';
import { toast } from 'react-toastify';
import { isBefore, startOfDay } from 'date-fns';

import clsx from 'clsx';
import FabButton from 'app/components/fabButton/FabButton';
import PlusIcon from 'app/assets/icons/Plus_icon';
import variables from 'app/utils/variables.module.scss';
import { lite } from '../../../services/apiService/apiService';
import AmplService from '../../../services/amplService';
import MomentAdapter from '@date-io/moment';
import { toZonedTime } from 'date-fns-tz';

const NewCaseFab = (): React.JSX.Element => {
  const { caseStore, metaDataStore, userStore } = useRootStore();
  const { t } = useTranslation();
  const [isShowFullHuddleButton, setIsShowFullHuddleButton] = useState(!getClickedHuddle());
  const dateLib = new MomentAdapter();
  const defaultDate = toZonedTime(new Date(), metaDataStore.tz);
  defaultDate.setHours(24, 0, 0, 0);
  const fixedDate = dateLib.moment(defaultDate).subtract(1, 'days');
  const isDisabled = isBefore(caseStore.selectedDateFilter, startOfDay(fixedDate.toDate()));

  /* If lite, only attending users can create new cases */
  if (lite && !userStore.loggedInUser.isAttending) {
    return <></>;
  }

  if (!lite && !userStore.loggedInUser.isAttending && !userStore.loggedInUser.data.isNurseLeader) {
    return <></>;
  }

  const handleClick = (): void => {
    if (isDisabled) {
      toast.info(`You cannot add a case in the past`);
      return;
    }
    caseStore.setIsNewCase(true);
    AmplService.sendEvent(AmplService.EVENTS.HOME_QUICK_CREATE_CASE);
    if (!getClickedHuddle()) {
      setClickedHuddle(true);
      setTimeout(() => {
        setIsShowFullHuddleButton(false);
      }, 1000);
    }
    setClickedHuddle(true);
  };
  return (
    <FabButton
      onClick={handleClick}
      classNames={clsx('new-case-fab', { isDisabled })}
      startIcon={
        <div className="button-icon">
          <PlusIcon stroke={variables.white} />
        </div>
      }
      buttonContent={
        <div className="text-expand-container">
          <div className={clsx('button-text', { isFull: isShowFullHuddleButton })}>
            {t('huddle')}
          </div>
        </div>
      }
    />
  );
};

export default observer(NewCaseFab);
