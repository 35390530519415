import { useRootStore } from 'app/mobxStore';
import type {
  ICareTeamDefaults,
  IDynamicField,
  IDynamicFieldValues,
  IFullCase,
  IGetFieldValuesCount
} from '../../mobxStore/types';
import { getFields } from './helper';
import { CASE_STATE } from 'app/consts';
import { useForm, type UseFormReset } from 'react-hook-form';
import type { Control, FormState, UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { log } from 'debug';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';
import AmplService from '../../services/amplService/amplService';

interface IUseBriefForm {
  fields: IDynamicField[];
  caseId: string;
  caseState: CASE_STATE;
  submitFunction: (data: IDynamicFieldValues) => Promise<void>;
  control: Control<IDynamicFieldValues>;
  formState: FormState<IDynamicFieldValues>;
  handleSubmit: UseFormHandleSubmit<IDynamicFieldValues>;
  allValues: IDynamicFieldValues;
  submitDefault: (data: IDynamicFieldValues) => Promise<void>;
  reset: UseFormReset<any>;
  loadTemplate: () => Promise<void>;
  setFocus: (fieldId: string) => void;
  toggleEditMode: () => Promise<void>;
}

const getDefaultValues = (
  fields: IDynamicField[],
  overRides: ICareTeamDefaults | undefined = undefined
): IDynamicFieldValues => {
  let defaultValues = {};
  fields.forEach(field => {
    const overRide = overRides !== undefined ? overRides.values[field.id] : undefined;
    const value = overRide !== undefined ? overRide.values : field.value;

    defaultValues = { ...defaultValues, [field.id]: value };
    if (field.hasOther) {
      const otherValue =
        overRide !== undefined ? overRide.otherValues : field.otherFieldProps.value;
      defaultValues = {
        ...defaultValues,
        [`${field.id}-other`]: otherValue
      };
    }
  });
  return defaultValues;
};

export const useBriefForm = (editMode: boolean): IUseBriefForm => {
  const rootStore = useRootStore();

  const { curEditMode, careTeamDefaultsStore, needsBriefApproval, caseStore, userStore } =
    rootStore;

  useEffect(() => {
    rootStore.setCurEditMode(editMode);
  }, []);
  const { refreshForm } = caseStore;
  const openedCase = caseStore.openedCase as IFullCase;
  const userData = userStore.loggedInUser.data;

  const getFieldValuesCount = (fieldId: string): IGetFieldValuesCount => {
    return caseStore.getFieldValuesCount(fieldId);
  };

  const toggleEditMode = async (): Promise<void> => {
    const curState = !curEditMode;
    openedCase.basicCase.updateCaseEditLock(curState);
    if (!curState) {
      await caseStore.openCase(openedCase.basicCase.id);
    }
    rootStore.setCurEditMode(curState);

    AmplService.sendCaseEvent(AmplService.EVENTS.EDIT_HUDDLE_CHANGE, openedCase?.basicCase, {
      [AmplService.ATTRIBUTES.EDITABLE]: curState
    });
  };

  const procedureId = openedCase.basicCase.data.procedureId as string;
  const savedDefaults = careTeamDefaultsStore.getDefault(
    openedCase.basicCase.data.attendingId,
    procedureId
  );
  console.log('curEditMode', curEditMode);
  const fields = getFields(
    openedCase,
    userData.roles,
    curEditMode,
    getFieldValuesCount,
    savedDefaults
  );
  const defaultValues = getDefaultValues(fields);
  const { setFocus, watch, reset, control, handleSubmit, formState } = useForm<any>({
    defaultValues
  });
  const allValues = watch();
  useEffect(() => {
    openedCase.basicCase.setIsResetting(true);
    reset(defaultValues);
  }, [refreshForm]);

  const submitFunction = async (data: IDynamicFieldValues): Promise<void> => {
    if (!needsBriefApproval) {
      ErrorMonitor.captureException(new Error('Unexpected. needsBriefApproval is false'));
      return;
    }
    try {
      await openedCase.basicCase.updateCaseMetaDataToServer({
        state: CASE_STATE.READY,
        isReady: true
      });
      openedCase.basicCase.setState(CASE_STATE.READY);
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
      toast.error(`Sorry, that didn't work... 🤷‍. Please try again.`);
      log(error);
    }
  };

  const loadTemplate = async (): Promise<void> => {
    const procedureId = openedCase.basicCase.data.procedureId;
    if (procedureId === null) {
      return;
    }
    const savedDefaults = careTeamDefaultsStore.getDefault(
      openedCase.basicCase.data.attendingId,
      procedureId
    );
    if (savedDefaults === undefined) {
      return;
    }

    const defaultValues = getDefaultValues(fields, savedDefaults);
    openedCase.basicCase.setIsResetting(true);
    reset(defaultValues);
  };

  const submitDefault = async (data: IDynamicFieldValues): Promise<void> => {
    try {
      await careTeamDefaultsStore.saveDefault(
        openedCase.basicCase.data.attendingId,
        procedureId,
        fields,
        data
      );
    } catch (error) {
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      ErrorMonitor.captureException(error as Error);
      log(error);
    }
  };

  return {
    fields,
    caseId: openedCase.basicCase.id,
    caseState: openedCase.basicCase.data.state,
    submitFunction,
    control,
    handleSubmit,
    formState,
    allValues,
    submitDefault,
    reset,
    loadTemplate,
    setFocus,
    toggleEditMode
  };
};
