import { authRoles } from 'app/auth';

import React, { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import { useAuth0 } from '@auth0/auth0-react';
import { setLogoutClicked } from '../../mobxStore/storage';

const Logout = (): React.JSX.Element => {
  const { logout } = useAuth0();
  const { userStore, metaDataStore } = useRootStore();
  useEffect(() => {
    const handleLogout = async (): Promise<void> => {
      if (!userStore.loggedInUser.data.roles.length) {
        // @ts-expect-error works
        window.location = '/login';
        return;
      }
      userStore.resetUser();
      setLogoutClicked(true);
      await logout();

      setTimeout(() => {
        metaDataStore.reset();
      }, 2000);
    };

    void handleLogout();
  }, []);
  return <div style={{ backgroundColor: '#fff' }}></div>;
};
const LogoutConfig = {
  auth: authRoles.notGuest,
  routes: [
    {
      path: '/logout',
      element: <Logout />
    }
  ]
};

export default LogoutConfig;
