import React from 'react';
import type { ICase } from 'app/mobxStore/types';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import './CaseListItem.scss';
import { observer } from 'mobx-react-lite';
import { showStandardProcedure } from '../../../../utils/form/shared';
import { toast } from 'react-toastify';

interface IProps {
  kase: ICase;
  showHuddleButton: boolean;
}

const CaseListItemStandardProc = (props: IProps): React.JSX.Element => {
  const {
    kase: { data: caseData },
    showHuddleButton
  } = props;
  const show = showHuddleButton && showStandardProcedure(caseData);

  const onClick = async (): Promise<void> => {
    await props.kase.setStandardProcedureToServer(true);
    props.kase.setStandardProcedureToStore(true);
    toast.success('Huddled! Marked this case as a standard procedure.');
  };

  return show ? (
    <Button
      id={'standard-proc-button'}
      classNames={clsx('standard-proc-button')}
      buttonText={'standardProcButton'}
      onClick={async e => {
        e.stopPropagation();
        void onClick();
      }}
    />
  ) : (
    <></>
  );
};

export default observer(CaseListItemStandardProc);
