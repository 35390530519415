import React, { type MouseEvent } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';

const MyCasesToggle = (): React.JSX.Element => {
  const { caseStore } = useRootStore();

  return (
    <div className="cases-toggle">
      <Button
        classNames={clsx('cases-toggle-button primary', { unselected: !caseStore.isMyCases })}
        buttonText="myCases"
        onClick={async (e: MouseEvent) => {
          caseStore.setIsMyCases(true);
        }}
      />
      <Button
        classNames={clsx('cases-toggle-button primary', { unselected: caseStore.isMyCases })}
        buttonText="allCases"
        onClick={async (e: MouseEvent) => {
          caseStore.setIsMyCases(false);
        }}
      />
    </div>
  );
};

export default observer(MyCasesToggle);
