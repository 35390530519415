import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'app/mobxStore';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import { lite } from 'app/services/apiService/apiService';
import { getClickedInvite, setClickedInvite } from '../../mobxStore/storage';
import clsx from 'clsx';
import Lottie, { type LottieRef } from 'lottie-react';
import IconButton from '@mui/material/IconButton';
import Filters from 'app/components/orBoard/filters/Filters';
import FiltersNonSurgeon from 'app/components/orBoard/filters/FiltersNonSurgeon';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import MyAccount from 'app/components/myAccount/MyAccount';
import Invite from 'app/pages/invite/Invite';
import AccountSettings from 'app/components/accountSettings/AccountSettings';

import AppIcon from 'app/assets/icons/AllApplication_icon';
import InviteUserIcon from 'app/assets/icons/InviteUser_icon';
import CheifyLoader from 'app/assets/lotties/chiefy-loader.json';
import variables from 'app/utils/variables.module.scss';
import './OrBoardHeader.scss';
import HuddleRateButton from './HuddleRateButton';
import AmplService from '../../services/amplService';

const OrBoardHeader = (): React.JSX.Element => {
  const { userStore, caseStore, liteNonSurgeon } = useRootStore();
  const { t } = useTranslation();
  const showUnimplemented = false;
  const showHuddleRate = userStore.loggedInUser.isAttending;
  const [isShowLiteLabel, setIsShowLiteLabel] = useState(false);
  const [logoAnimationDone, setLogoAnimationDone] = useState(false);
  const chiefyLogoAnimationRef: LottieRef = React.createRef();
  const [isShowFullInviteButton, setIsShowFullInviteButton] = useState(!getClickedInvite());

  useEffect(() => {
    if (getClickedInvite()) {
      setTimeout(() => {
        setIsShowFullInviteButton(false);
      }, 1000);
    }
  }, [getClickedInvite()]);

  useEffect(() => {
    if (chiefyLogoAnimationRef.current) {
      chiefyLogoAnimationRef.current.goToAndStop(39, true);
    }
  }, [chiefyLogoAnimationRef.current]);

  useEffect(() => {
    const animateLogo = (): void => {
      if (chiefyLogoAnimationRef.current) {
        setTimeout(() => {
          chiefyLogoAnimationRef.current?.playSegments(
            [
              [39, 78],
              [0, 39]
            ],
            true
          );
        }, 400);
      }
    };

    if (!logoAnimationDone && caseStore.items.length > 0) {
      setLogoAnimationDone(true);
      animateLogo();
    }
  }, [caseStore.items.length]);

  const handleCloseMyAccount = async (): Promise<void> => {
    caseStore.setIsMyAccountDialogOpen(false);
  };

  const handleCloseInviteDialog = async (): Promise<void> => {
    caseStore.setIsInviteDialogOpen(false);
  };

  const handleCloseSettingsDialog = async (): Promise<void> => {
    caseStore.setIsSettingsDialogOpen(false);
  };

  return (
    <div className={clsx('header-container', { 'non-surgeon': liteNonSurgeon })}>
      <div className="header-main">
        <div className="logo-container">
          <div className="logo">
            <Lottie
              animationData={CheifyLoader}
              lottieRef={chiefyLogoAnimationRef}
              loop={false}
              autoplay={false}
              className="lottie-container"
              onComplete={() => {
                lite && setIsShowLiteLabel(true);
              }}
            />
          </div>
          {lite && isShowLiteLabel && <div className="beta-label">{t('lite')}</div>}
        </div>
        <div className="user-menu">
          {showHuddleRate && <HuddleRateButton />}
          {showUnimplemented && (
            <IconButton className="menu-button">
              <AppIcon height={20} stroke={variables.grey6} />
            </IconButton>
          )}
          {userStore.loggedInUser.isInviter && (
            <IconButton
              className="menu-button invite"
              onClick={() => {
                caseStore.setIsInviteDialogOpen(true);
                setClickedInvite(true);
                AmplService.sendEvent(AmplService.EVENTS.HOME_INVITE);
              }}
            >
              <div className="button-icon">
                <InviteUserIcon height={20} fill={variables.primaryPurple} />
              </div>
              <div className="text-expand-container">
                <div className={clsx('button-text', { isShown: isShowFullInviteButton })}>
                  {t('inviteTeam')}
                </div>
              </div>
            </IconButton>
          )}
          <IconButton
            className="menu-button account"
            onClick={() => {
              caseStore.setIsMyAccountDialogOpen(true);
              AmplService.sendEvent(AmplService.EVENTS.HOME_MY_ACCOUNT);
            }}
          >
            <UserAvatar user={parseUserToSelectOption(userStore.loggedInUser.data)} />
          </IconButton>
          <ModalScreen
            classNames="my-account-dialog"
            isOpen={caseStore.isMyAccountDialogOpen}
            content={<MyAccount />}
            handleClose={handleCloseMyAccount}
          />
          <ModalScreen
            classNames="invite-dialog"
            isOpen={caseStore.isInviteDialogOpen}
            content={<Invite handleCloseInviteDialog={handleCloseInviteDialog} />}
            handleClose={handleCloseInviteDialog}
            onCloseEnd={() => {
              userStore.setInviteId(null);
              if (caseStore.isNewSiteInvite) {
                caseStore.setIsNewSiteInvite(false);
              }
            }}
          />
          <ModalScreen
            classNames="settings-dialog"
            isOpen={caseStore.isSettingsDialogOpen}
            content={<AccountSettings handleCloseSettingsDialog={handleCloseSettingsDialog} />}
            handleClose={handleCloseSettingsDialog}
          />
        </div>
      </div>
      {liteNonSurgeon ? <FiltersNonSurgeon /> : <Filters />}
    </div>
  );
};

export default observer(OrBoardHeader);
