import React, { type MouseEvent, useEffect, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import ShareIcon from 'app/assets/icons/Share_icon';
import PageIcon from 'app/assets/icons/Page_icon';
import SpeechIcon from 'app/assets/icons/SpeechBubble_icon';
import variables from 'app/utils/variables.module.scss';
import './FillCaseMetaForm.scss';
import { type IFullCase, TabsEnum } from '../../mobxStore/types';
import useOurSearchParams from '../caseViews/hooks/useOurSearchParams';
import SavingStatusCmp from './SavingStatusCmp';
import AmplService from '../../services/amplService';
import TooltipWrapper from 'app/components/tooltipWrapper/TooltipWrapper';
import { getClickedChatTabTooltip, setClickedChatTabTooltip } from 'app/mobxStore/storage';
import CaseListItemMenu from 'app/components/orBoard/caseListItem/caseListItemMenu/CaseListItemMenu';

interface IProps {
  handleClose: () => Promise<void>;
  dateToPostponeCase?: Date;
  setDateToPostponeCase: (date: Date) => void;
}

const HuddleTabs = (props: IProps): React.JSX.Element => {
  const { handleClose, dateToPostponeCase, setDateToPostponeCase } = props;
  const { caseStore, userStore } = useRootStore();
  const { huddleScrolled, selectedTabInHuddle } = caseStore;
  const { setOurSearchParams } = useOurSearchParams();
  const openedCase = caseStore.openedCase as IFullCase;
  const [isChatTabTooltipOpen, setIsChatTabTooltipOpen] = useState(false);
  const { t } = useTranslation();

  const unreadCommentsCount = openedCase?.commentsStore.getCommentsUnreadCount() ?? 0;
  const isNeedsAttention = openedCase?.basicCase.needsAttention(caseStore.currentUserPrevLastSeen);

  const onCloseClick = async (e: MouseEvent): Promise<void> => {
    await handleClose();
    AmplService.sendCaseEvent(AmplService.EVENTS.CASE_BACK, openedCase.basicCase);
  };

  const handleCloseChatTabTooltip = (): void => {
    setIsChatTabTooltipOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const showToolTip =
        selectedTabInHuddle === TabsEnum.CASE &&
        !getClickedChatTabTooltip() &&
        openedCase?.basicCase.data.procedureId !== null;
      if (!showToolTip) {
        return;
      }
      if (caseStore.huddleScrolledToBottom) {
        setIsChatTabTooltipOpen(true);
      }
    });
  }, [caseStore.huddleScrolledToBottom]);

  const getChatTab = (children: JSX.Element): JSX.Element => {
    if (getClickedChatTabTooltip()) {
      return children;
    }
    const loggedInUser = userStore.loggedInUser.data;
    const attending = caseStore.openedCase?.basicCase.data.attending;
    const tooltipText =
      loggedInUser.id === attending?.id
        ? t('chatTabTooltipTextSurgeon')
        : t('chatTabTooltipTextNonSurgeon');
    return (
      <TooltipWrapper
        open={isChatTabTooltipOpen}
        name={'chatTab'}
        handleCloseTooltip={handleCloseChatTabTooltip}
        handleSetTooltipSeen={() => {
          setClickedChatTabTooltip(true);
        }}
        comp={children}
        placement="bottom"
        offset={[0, -4]}
        tooltipContent={<Trans i18nKey={tooltipText} values={{ attending: attending?.nickName }} />}
      />
    );
  };

  const chatTab = getChatTab(
    <div>
      <Button
        classNames={clsx('btn outlined tab chat', {
          selected: selectedTabInHuddle === TabsEnum.CHAT
        })}
        startIcon={
          <div className="icon-container">
            <SpeechIcon fill={selectedTabInHuddle === TabsEnum.CHAT ? null : variables.grey5} />
            {unreadCommentsCount > 0 && (
              <div className={clsx('tab-badge', { wide: unreadCommentsCount >= 100 })}>
                {unreadCommentsCount < 100 ? unreadCommentsCount : '99+'}
              </div>
            )}
          </div>
        }
        onClick={async (e: MouseEvent) => {
          if (!getClickedChatTabTooltip()) {
            setClickedChatTabTooltip(true);
          }
          setOurSearchParams({ tab: TabsEnum.CHAT }, false);
          AmplService.sendCaseEvent(AmplService.EVENTS.CASE_TAB, openedCase.basicCase, {
            [AmplService.ATTRIBUTES.TAB]: TabsEnum.CHAT
          });
        }}
      />
    </div>
  );
  return (
    <div className={clsx('huddle-tabs', { withShadow: huddleScrolled })}>
      <div className="flex-container">
        <Button
          classNames="circle-button back-button"
          startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
          onClick={onCloseClick}
        />
      </div>
      <div className="tabs">
        <Button
          classNames={clsx('btn outlined tab', { selected: selectedTabInHuddle === TabsEnum.CASE })}
          startIcon={
            <div className="icon-container">
              <PageIcon fill={selectedTabInHuddle === TabsEnum.CASE ? null : variables.grey5} />
              {isNeedsAttention && <div className="tab-badge attention"></div>}
            </div>
          }
          onClick={async (e: MouseEvent) => {
            setOurSearchParams({ tab: TabsEnum.CASE }, false);
            AmplService.sendEvent(AmplService.EVENTS.CASE_TAB, {
              [AmplService.ATTRIBUTES.TAB]: TabsEnum.CASE
            });
          }}
        />
        {chatTab}
      </div>
      {false && (
        <div className="flex-container right-align">
          <Button
            classNames="btn circle-button"
            startIcon={<ShareIcon height={14} stroke={variables.black} />}
          />
        </div>
      )}
      <div className="flex-container right-align">
        <CaseListItemMenu
          case={openedCase.basicCase}
          dateToPostponeCase={dateToPostponeCase}
          setDateToPostponeCase={setDateToPostponeCase}
        />
      </div>
      <SavingStatusCmp />
    </div>
  );
};

export default observer(HuddleTabs);
